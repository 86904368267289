<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Chương trình đào tạo/Bồi dưỡng"
                    label-for="trainingSystem"
                  >
                    <v-select
                      id="trainingSystem"
                      v-model="filter.trainingSystemId"
                      label="name"
                      :options="trainingSystems"
                      :reduce="option => option.id"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Khóa học"
                    label-for="course"
                  >
                    <v-select
                      id="course"
                      v-model="filter.courseId"
                      label="name"
                      :options="courses"
                      :reduce="option => option.id"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Học kỳ"
                    label-for="semester"
                  >
                    <v-select
                      id="semester"
                      v-model="filter.courseSemesterId"
                      label="name"
                      :options="semesters"
                      :reduce="option => option.id"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="w-100 d-flex justify-content-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="getDataSourcesFromStore"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="SearchIcon" /> Tìm kiếm
                      </span>
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      @click="exportExcel"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="DownloadIcon" /> Xuất Excel
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12">
                  <vue-good-table
                    :columns="columns"
                    :rows="dataSources"
                    :pagination-options="paginationOptions"
                    :line-numbers="true"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>

                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span>{{ props.formattedRow[props.column.field] }}</span>
                    </template>

                    <!-- pagination -->
                    <template
                      slot="pagination-bottom"
                      slot-scope="props"
                    >
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap"> Hiển thị 1 đến </span>
                          <b-form-select
                            v-model="itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                          />
                          <span class="text-nowrap">của {{ dataSources.length }} bản ghi</span>
                        </div>
                        <div>
                          <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                          />
                        </div>
                      </div>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import * as XLSX from 'xlsx'
export default {
  name: 'StudentBannedExamination',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BForm,
    BFormGroup,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
      },
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã học viên',
          field: 'studentCode',
          thClass: 'text-center',
        },
        {
          label: 'Họ tên',
          field: 'fullName',
          thClass: 'text-center',
        },
        {
          label: 'Mã lớp độc lập',
          field: 'creditClassCode',
          thClass: 'text-center',
        },
        {
          label: 'Môn học',
          field: 'subjectName',
          thClass: 'text-center',
        },
        {
          label: 'Lý do',
          field: 'reasonBan',
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'studentExaminationRoom/studentsBannedExamination',
      trainingSystems: 'trainingSystem/trainingSystems',
      courses: 'course/courses',
      semesters: 'semester/semesters',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  watch: {
    'filter.trainingSystemId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          this.updateParams({ courseId: null })
          await this.getCoursesByTrainingSystemId({ organizationId: this.user.orgId, trainingSystemId: val })
          if (this.courses.length > 0) {
            this.updateParams({ courseId: this.courses[0].id })
          } else {
            this.updateParams({ courseId: null, courseSemesterId: null })
            this.setCourses([])
            this.setSemesters([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    'filter.courseId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          this.updateParams({ courseSemesterId: null })
          await this.getSemestersByCourseId({ courseId: val })
          if (this.semesters.length > 0) {
            this.updateParams({ courseSemesterId: this.semesters[0].id })
          } else {
            this.updateParams({ courseSemesterId: null })
            this.setSemesters([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({ organizationId: this.user.orgId })
      if (this.trainingSystems.length > 0) {
        this.updateParams({ trainingSystemId: this.trainingSystems[0].id })
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setCourses: 'course/SET_COURSES',
      setSemesters: 'dropdown/SET_COURSE_SEMESTERS',
    }),
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'trainingSystem/getTrainingSystemsByOrganizationId',
      getCoursesByTrainingSystemId: 'course/getCoursesByTrainingSystemId',
      getSemestersByCourseId: 'semester/getSemestersByCourseId',
      getStudentBannedExamination: 'studentExaminationRoom/getStudentBannedExamination',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getStudentBannedExamination(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    exportExcel() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const data = []
      const header = {}
      this.columns.forEach(column => {
        header[column.field] = column.label
      })
      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(this.dataSources[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      this.dataSources.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        data.push(elm)
      })
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SinhVien')
      XLSX.writeFile(wb, 'DanhSachSinhVienKhongDuocThi.xlsx')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
